<template>
  <v-dialog
    v-model="dialogSplitPackage"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="oee-theme">
        <v-btn icon dark @click="closePopup">
          <v-icon>close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title
          >{{isChinessLanguage ? '复制' : 'Tạo mã tách'}} #{{ currentItem.TransactionCode }}</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text>
        <v-layout row wrap pa-3>
          <v-flex lg5 md5 sm5 v-if="!readOnly">
            <v-layout row wrap pa-3 pt-12>
              <v-flex lg12 md12 sm12 pa-0>
                <h-currency-input
                  v-model="splitNumber"
                  :decimal="0"
                  :label="isChinessLanguage ? '数量' : 'Số lượng'"
                  ref="splitnumber_elm"
                  class="input-group--focused"
                  @press_enter="onSplitPackage"
                ></h-currency-input>
              </v-flex>
              <!-- <v-flex lg12 md12 sm12 pa-0>
                <h-currency-input
                  v-model="splitItem.Weight"
                  :decimal="2"
                  label="Kg"
                  ref="weight_elm"
                  class="input-group--focused"
                ></h-currency-input>
              </v-flex> -->
              <!-- <v-flex lg12 md12 sm12 pa-0>
                <h-currency-input
                  ref="length_elm"
                  v-model="splitItem.Length"
                  :decimal="0"
                  :label="isChinessLanguage ? '长 (cm)' : 'Chiều dài (cm)'"
                  class="input-group--focused"
                ></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 pa-0>
                <h-currency-input
                  v-model="splitItem.Width"
                  :decimal="0"
                  :label="isChinessLanguage ? '宽 (cm)' : 'Chiều rộng (cm)'"
                  class="input-group--focused"
                ></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 pa-0>
                <h-currency-input
                  v-model="splitItem.Height"
                  :decimal="0"
                  :label="isChinessLanguage ? '高 (cm)' : 'Chiều cao (cm)'"
                  class="input-group--focused"
                ></h-currency-input>
              </v-flex> -->
              <!-- <v-flex lg12 md12 sm12 pa-0>
                <v-autocomplete clearable class="input-group--focused" :label="isChinessLanguage ? '分类' : 'Loại hàng'" v-model="splitItem.ProductType" :items="product_types" item-text="name" item-value="value"></v-autocomplete>
              </v-flex>
              <v-flex lg12 md12 sm12 pa-0>
                <h-currency-input
                  v-model="splitItem.FeeShip"
                  :decimal="1"
                  :label="isChinessLanguage ? '到付(¥)' : 'Phí ship (¥)'"
                  class="input-group--focused"
                ></h-currency-input>
              </v-flex> -->
              <v-flex lg12 md12 sm12>
                <v-btn class="ma-2" @click="onSplitPackage">{{!isChinessLanguage ? 'Nhập' : '输入'}}</v-btn>
                <v-btn class="ma-2" @click="onPrintAll">{{!isChinessLanguage ? 'In tem' : '打印'}}</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex :lg7="!readOnly" :md7="!readOnly" :sm7="!readOnly" :lg12="readOnly" :md12="readOnly" :sm12="readOnly">
            <v-layout pl-4 pr-2 pt-12>
              <v-flex lg12 md12 sm12>
                <v-data-table
                  :must-sort="false"
                  :headers="sub_headers"
                  :items="currentPackages"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item="{ item, index }">
                    <tr>
                        <td>{{ item.TransactionCode }}</td>
                        <td class="text-end">{{ item.Weight | currency("", 2) }}</td>
                        <!-- <td class="text-end" v-else>
                            <v-edit-dialog
                            :return-value.sync="item.Weight"
                            large
                            lazy
                            persistent
                            @save="onSplitPackage(false)"
                            >
                            <div>{{ item.Weight | currency("", 2) }}</div>
                            <template v-slot:input>
                                <h-currency-input
                                v-model="item.Weight"
                                :decimal="2"
                                label="Kg"
                                autofocus
                                ></h-currency-input>
                            </template>
                            </v-edit-dialog>
                        </td> -->
                        <!-- <td class="text-end" v-if="readOnly">{{ item.Length | currency }}</td>
                        <td class="text-end" v-else>
                            <v-edit-dialog
                            :return-value.sync="item.Length"
                            large
                            lazy
                            persistent
                            @save="onSplitPackage(false)"
                            >
                            <div>{{ item.Length | currency }}</div>
                            <template v-slot:input>
                                <h-currency-input
                                v-model="item.Length"
                                :decimal="0"
                                label="Chiều dài (cm)"
                                autofocus
                                ></h-currency-input>
                            </template>
                            </v-edit-dialog>
                        </td>
                        <td class="text-end" v-if="readOnly">{{ item.Width | currency }}</td>
                        <td class="text-end" v-else>
                            <v-edit-dialog
                            :return-value.sync="item.Width"
                            large
                            lazy
                            persistent
                            @save="onSplitPackage(false)"
                            >
                            <div>{{ item.Width | currency }}</div>
                            <template v-slot:input>
                                <h-currency-input
                                v-model="item.Width"
                                :decimal="0"
                                label="Chiều rộng (cm)"
                                autofocus
                                ></h-currency-input>
                            </template>
                            </v-edit-dialog>
                        </td>
                        <td class="text-end" v-if="readOnly">{{ item.Height | currency }}</td>
                        <td class="text-end" v-else>
                            <v-edit-dialog
                            :return-value.sync="item.Height"
                            large
                            lazy
                            persistent
                            @save="onSplitPackage(false)"
                            >
                            <div>{{ item.Height | currency }}</div>
                            <template v-slot:input>
                                <h-currency-input
                                v-model="item.Height"
                                :decimal="0"
                                label="Chiều cao (cm)"
                                autofocus
                                ></h-currency-input>
                            </template>
                            </v-edit-dialog>
                        </td> -->
                        <td class="text-end">{{ item.Amount | currency }}</td>
                        <!-- <td v-if="readOnly">{{ item.ProductType | display_value(product_types) }}</td>
                        <td>
                            <v-edit-dialog :return-value.sync="item.ProductType" large lazy persistent @save="onSplitPackage(false)">
                                <div>{{ item.ProductType | display_value(product_types) }}</div>
                                <template v-slot:input>
                                    <v-autocomplete clearable class="input-group--focused" :label="isChinessLanguage ? '分类' : 'Loại hàng'" v-model="item.ProductType" :items="product_types" item-text="name" item-value="value"></v-autocomplete>
                                </template>
                            </v-edit-dialog>
                        </td>
                        <td v-if="readOnly" class="text-end">{{ item.FeeShip | currency('¥', 1) }}</td>
                        <td class="text-end">
                            <v-edit-dialog :return-value.sync="item.FeeShip" large lazy persistent @save="onSplitPackage(false)">
                                <div>{{ item.FeeShip | currency('¥', 1) }}</div>
                                <template v-slot:input>
                                    <h-currency-input v-model="item.FeeShip" :decimal="1" label="Phí ship" autofocus></h-currency-input>
                                </template>
                            </v-edit-dialog>
                        </td>                       -->
                        <td class="text-center">
                          <v-icon small @click="deleteSubItem(index)" v-if="!readOnly">delete</v-icon>
                          <v-icon small color="#dc3545" @click="printTransaction(item)">fa-print</v-icon>
                        </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import configs from "@/configs";
import { focusNextElement } from "@/commons/utils";
import { print_transaction_v2, request_detail } from "@/commons/hpod";
import Vue from 'vue';
export default {
  props: ["currentItem", "dialogSplitPackage", "readOnly"],
  data() {
    return {
      splitNumber: 1,
      splitItem: { NumberOfPack: 1 },
      focusNext: null
    };
  },
  created() {
    this.focusNext = focusNextElement;
  },
  computed: {
    sub_headers() {
      return [
        {
          text: this.isChinessLanguage ? "单号" : "Mã bill",
          value: "TransactionCode",
          sortable: false
        },
        {
          text: "Kg",
          value: "Weight",
          sortable: false,
          align: "end",
        },
        // {
        //   text: this.isChinessLanguage ? "长" : "Dài",
        //   value: "Length",
        //   sortable: false,
        //   align: "end",
        // },
        // {
        //   text: this.isChinessLanguage ? "宽" : "Rộng",
        //   value: "Width",
        //   sortable: false,
        //   align: "end",
        // },
        // {
        //   text: this.isChinessLanguage ? "高" : "Cao",
        //   value: "Height",
        //   sortable: false,
        //   align: "end",
        // },
        {
          text: this.isChinessLanguage ? "价格" : "Giá",
          value: "Amount",
          sortable: false,
          align: "end",
        },
        // {
        //   text: this.isChinessLanguage ? "分类" : "Loại hàng",
        //   value: "TotalWeight",
        //   sortable: false
        // },
        // {
        //   text: this.isChinessLanguage ? "到付" : "Phí ship (¥)",
        //   value: "FeeVolume",
        //   sortable: false,
        //   align: "end",
        // },
        {
          text: this.isChinessLanguage ? "操作" : "Thao tác",
          sortable: false,
          align: "center",
        },
      ]
    },
    ...mapState({
      configuration: state => state.configuration.selected
    }),
    currentPackages() {
      return this.currentItem && this.currentItem.Packages
        ? JSON.parse(this.currentItem.Packages)
        : [];
    },
    isChinessLanguage() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
    },
    product_types() {
      return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
    }
  },
  methods: {
    closePopup() {
      this.$store.commit("orderTransaction/setDetail", {data: {}});
      this.$emit("closePopup");
    },
    filter_data() {
      if(this.currentItem.ID > 0) {
        this.$store.dispatch("orderTransaction/getDetail", this.currentItem.ID);
      }
    },
    onSplitPackage(isNew = true) {
      let split_packages = [...this.currentPackages];
      if (isNew) {
        for (let i = 0; i < this.splitNumber; i++) {
          split_packages.push({...this.splitItem});          
        }
      }
      this.currentItem.Packages = JSON.stringify(split_packages);
      // if (isNew) {
      //   this.splitItem = { NumberOfPack: 1 };
      //   this.$nextTick(() => this.$refs.splitnumber_elm.focus());
      // }
      this.$store.dispatch("orderTransaction/setDetail", {
        data: {
          Packages: this.currentItem.Packages,
        },
        id: this.currentItem.ID,
      });
    },
    deleteSubItem(index) {
      let split_packages = [...this.currentPackages];
      split_packages.splice(index, 1);
      this.currentItem.Packages = JSON.stringify(split_packages);
      this.$store.dispatch("orderTransaction/setDetail", {
        data: {
          Packages: this.currentItem.Packages,
        },
        id: this.currentItem.ID,
      });
    },
    printTransaction(item) {
      this.$store.commit("orderTransaction/setLoading", { loading: true });
      request_detail('order_transactions/print', ret => {
        let parrent_item = ret.data;
        this.$store.commit("orderTransaction/setLoading", { loading: false });
        const frame_printing = print_transaction_v2(parrent_item, this.$store.state.authentication.user.username, true, item);
        var doc = document.getElementById('print_frame').contentWindow.document;
        doc.open();
        doc.write(frame_printing);
        doc.close();
        setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
      }, Vue.http, this.currentItem.ID, this.$store.state.authentication.token);
    },
    onPrintAll() {
      this.$store.commit("orderTransaction/setLoading", { loading: true });
      request_detail('order_transactions/print', ret => {
        let parrent_item = ret.data;
        this.$store.commit("orderTransaction/setLoading", { loading: false });
        const frame_printing = print_transaction_v2(parrent_item, this.$store.state.authentication.user.username, true, null, true);
        var doc = document.getElementById('print_frame').contentWindow.document;
        doc.open();
        doc.write(frame_printing);
        doc.close();
        setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
      }, Vue.http, this.currentItem.ID, this.$store.state.authentication.token);
    }
  },
  mounted() {
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
  }
};
</script>
